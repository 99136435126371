import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

export function defineMeta() { 0 }


const filerouter = (options = {}) => {
    const { routesHook = v => v, ...resetOpts } = options
    const createRouterParams = {
        history: createWebHistory(),
        routes: routesHook(routes) || routes,
        ...resetOpts
    }
    console.log({ createRouterParams }, routes)
    return createRouter(createRouterParams)

}
export default filerouter

