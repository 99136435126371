/* eslint-disable quotes */



const routes= [
	{
		"path": "/janus",
		"name": "/janus",
		"component": () => import('../views/janus.vue')
	},
	{
		"path": "/shop",
		"name": "/shop",
		"component": () => import('../views/shop.vue')
	},
	{
		"path": "/tts-iframe",
		"name": "/tts-iframe/",
		"component": () => import('../views/tts-iframe/index.vue')
	},
	{
		"path": "/tts-iframe/BaseCfg",
		"name": "/tts-iframe/BaseCfg",
		"component": () => import('../views/tts-iframe/BaseCfg.vue')
	},
	{
		"path": "/tts-iframe/Saying",
		"name": "/tts-iframe/Saying",
		"component": () => import('../views/tts-iframe/Saying.vue')
	},
	{
		"path": "/tts-iframe/mobile",
		"name": "/tts-iframe/mobile",
		"component": () => import('../views/tts-iframe/mobile.vue')
	},
	{
		"path": "/tts-iframe/pc",
		"name": "/tts-iframe/pc",
		"component": () => import('../views/tts-iframe/pc.vue')
	},
	{
		"path": "/tts2",
		"name": "/tts2",
		"component": () => import('../views/tts2.vue')
	}
]
export default routes